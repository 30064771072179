import Api from "@/apis/Api"
import moment from "moment";

export default {
	data() {
		return {
			lang: {
				"lets-encrypt": this.$t('general.sslProvider.letsEncrypt'),
				"self-signed": this.$t('general.sslProvider.selfSigned'),
			},
		};
	},
	methods: {
		loadEditSslModalFromInstance(instance, button) {
			if (instance.hostingServerType === 'wp-cloud') {
				this.showWpCloudEditSslModal(instance);
				return;
			}

			button.loading = true;
			Api.hosting(instance.hostingId).getInstalledCertificate(instance.domain).then(async (certs) => {
				await Api.cached().get('/ssl-offer').then((offerResponse) => {
					this.sslOffers = offerResponse.data.data;
				});
				if (typeof instance.sslInstalledCert === "undefined") {
					Api.hosting(instance.hostingId)
						.getInstalledCertificate(instance.domain)
						.then((response) => {
							instance.sslInstalledCert = response;
							this.showEditSslModal(instance, [certs], 'instance');
						})
				} else {
					this.showEditSslModal(instance, [certs], 'instance');
				}
			}).catch((error) => {
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: Api.getErrorMessage(error),
				});
			}).finally(() => {
				button.loading = false;
			});
		},
		loadEditSslModal(item) {

			if (this.selectedServerAccount.server_type === 'wp-cloud') {
				this.showWpCloudEditSslModal(item);
				return;
			}

			item.editSslButtonLoading = true;
			Api.hosting(item.serverAccountId).getInstalledCertificate(item.domain).then(async (response) => {
				await Api.cached().get('/ssl-offer').then((offerResponse) => {
					this.sslOffers = offerResponse.data.data;
				});
				let certs = [];
				if (response) {
					certs = [response];
				}
				this.showEditSslModal(item, certs, 'domain');
			}).catch((error) => {
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: Api.getErrorMessage(error),
				});
			}).finally(() => {
				item.editSslButtonLoading = false;
			});
		},
		showEditSslModal(item, certs, type) {
			this.resetModal();
			this.modalOptions.item = {
				certificate_id: item.sslInstalledCert?.id || null,
				domain: item
			};
			this.modalOptions.title = this.$t('heading.instance.editSsl.modal.title');
			this.modalOptions.icon = "$lockoutline";
			this.modalOptions.message = `${this.$t('general.domain')}: <strong>${item.domain}</strong>`;
			this.modalOptions.messageClass = 'p-3';
			this.modalOptions.actionsClasses = 'pt-8';

			if (type === 'domain' && item.has_dns_zone || (type === 'instance' && item.domain_has_dns_zone)) {
				if (this.$store.state.home.app.order_custom_ssl_certificate_link) {
					this.modalOptions.affixLink = {
						external: true,
						hidden: false,
						url: this.$store.state.home.app.order_custom_ssl_certificate_link,
						message: this.$t('heading.instance.editSsl.modal.orderLink'),
					};
				}

				this.modalOptions.buttons.unshift({
					label: this.$t('button.saveChanges'),
					color: "primary",
					onclick: (modal) => {
						modal.$refs.form.validate()
						&& this.updateDomainSsl(item, modal.formValues);
					},
				});
				certs = certs.filter((c) => {
					return !(typeof c === 'undefined' || c === null);
				})
				let options = certs.map((c) => {
					let label = c.issuer_name || this.$t('general.sslProvider.selfSigned');
					let expiryDate = "";
					if (!isNaN(parseFloat(c.not_after)) && isFinite(c.not_after)) {
						expiryDate = moment.unix(c.not_after);
					} else {
						expiryDate = moment(c.not_after);
					}
					if (expiryDate.isBefore()) {
						label += this.$t('form.option.ssl.suffix.before', {date: expiryDate.fromNow()})
					} else {
						label += this.$t('form.option.ssl.suffix.after', {date: expiryDate.fromNow()})
					}
					return {
						label: label,
						value: c.id,
					};
				});
				if (this.sslOffers.length) {
					for (const offer of this.sslOffers) {
						let label = this.$t('form.option.ssl.generate', {
							provider: this.lang[offer.slug]
						});
						options.push({
							label: label,
							value: offer.id,
						});
					}
				}
				options.push({
					label: this.$t('form.option.ssl.custom'),
					value: "custom",
				});
				if (!this.modalOptions.item.certificate_id) {
					this.modalOptions.item.certificate_id = options[0].value;
				}

				this.modalOptions.formFields = [
					{
						type: "select",
						name: "certificate_id",
						messageClass: 'p-3',
						options: options,
						required: true,
						onChange: (value) => {
							if (value.certificate_id === "custom") {
								if (this.$store.state.home.app.order_custom_ssl_certificate_link) {
									this.$refs.modal.modalOptions.affixLink.hidden = true;
								}
								this.$refs.modal.showFields([
									"certificate",
									"private_key",
									"ca_bundle",
								]);
							} else {
								if (this.$store.state.home.app.order_custom_ssl_certificate_link) {
									this.$refs.modal.modalOptions.affixLink.hidden = false;
								}
								this.$refs.modal.hideFields([
									"certificate",
									"private_key",
									"ca_bundle",
								]);
							}
						},
					},
					{
						hidden: item.certificate_id !== "custom" ? true : false,
						type: "textarea",
						height: "112px",
						messageClass: 'p-3 mb-2',
						name: "certificate",
						message: this.$t('form.label.certificate'),
						placeholder: this.$t('form.placeholder.certificate'),
					},
					{
						hidden: item.certificate_id !== "custom" ? true : false,
						type: "textarea",
						height: "112px",
						messageClass: 'p-3 mb-2',
						name: "private_key",
						message: this.$t('form.label.privateKeyWithShortcut'),
						placeholder: this.$t('form.placeholder.certificateKey'),
					},
					{
						hidden: item.certificate_id !== "custom" ? true : false,
						type: "textarea",
						height: "112px",
						messageClass: 'p-3 mb-2',
						name: "ca_bundle",
						message: this.$t('form.label.cabundle'),
						placeholder: this.$t('form.placeholder.cabundle'),
					},
				];
			}
			this.modalOptions.open = true;
		},
		updateDomainSsl(item, formData) {
			if (item.sslInstalledCert?.id == formData.certificate_id) {
				this.modalClose();
				return;
			}
			if (this.sslOffers.map(o => o.id).includes(formData.certificate_id)) {
				return this.orderDomainSsl(item, formData);
			}
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			let serverAccountId = item.serverAccountId || item.hostingId;
			Api.put(`/server-accounts/${serverAccountId}/ssl/certificates/install`, {
				domain: item.domain,
				certificate_id: formData.certificate_id != "custom" ? formData.certificate_id : null,
				certificate: formData.certificate,
				key: formData.private_key,
				cabundle: formData.ca_bundle,
			}).then(() => {
				this.$store.dispatch("addAlert", {
					success: true,
					successMessage: this.$t('notification.instance.editSsl.edit.success')
				});
				this.$emit("ssl-certificate-installed", item)
				this.modalClose();
			}).catch(error => {
				this.modalOptions.submittingError = Api.getErrorMessage(error);
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: Api.getErrorMessage(error),
				});
			}).finally(() => {
				this.modalOptions.persistent = false;
				this.modalOptions.submitting = false;
			});
		},
		orderDomainSsl(item, formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			let serverAccountId = item.serverAccountId || item.hostingId;
			Api.post(`/server-accounts/${serverAccountId}/ssl/certificates/order`, {
				domain: item.domain,
				provider_id: formData.certificate_id,
			}).then(() => {
				this.$store.dispatch("addAlert", {
					success: true,
					successMessage: this.$t('notification.instance.editSsl.order.success')
				});
				this.$emit("ssl-certificate-ordered", item)
				this.modalClose();
			}).catch(error => {
				this.modalOptions.submittingError = Api.getErrorMessage(error);
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: Api.getErrorMessage(error),
				});
			}).finally(() => {
				this.modalOptions.persistent = false;
				this.modalOptions.submitting = false;
			});
		},
		showWpCloudEditSslModal(item) {
			this.resetModal();
			this.modalOptions.item = {
				domain: item
			};
			this.modalOptions.title = this.$t('heading.instance.editSsl.modal.title');
			this.modalOptions.icon = "$lockoutline";
			this.modalOptions.message = `${this.$t('general.domain')}: <strong>${item.domain}</strong>`;
			this.modalOptions.messageClass = 'p-3';
			this.modalOptions.actionsClasses = 'pt-8';

			this.modalOptions.buttons.unshift({
				label: this.$t('button.retry.ssl'),
				color: "primary",
				onclick: () => {
					this.retrySslCertificate(item);
				},
			});
			this.modalOptions.open = true;
		},
		retrySslCertificate(item) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;

			let serverAccountId = item.serverAccountId || item.hostingId;
			Api.post(`/server-accounts/${serverAccountId}/ssl/certificates/retry`, {
				domain: item.domain,
			}).then(() => {
				this.$store.dispatch("addAlert", {
					success: true,
					successMessage: this.$t('notification.instance.editSsl.order.success')
				});
				this.modalClose();
			}).catch(error => {
				this.modalOptions.submittingError = Api.getErrorMessage(error);
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: Api.getErrorMessage(error),
				});
			}).finally(() => {
				this.modalOptions.persistent = false;
				this.modalOptions.submitting = false;
			});
		}
	}
}